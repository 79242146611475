// import WavelengthEMR from "./emr.png"
// import SpaceInvaders from "./space-invaders.png"
// import TicTacToe from "./tic-tac-toe.png"
import ErrorGif from "./error-gif.gif"



export default {
    // wavelengthEMR: WavelengthEMR,
    // spaceInvaders: SpaceInvaders,
    // ticTacToe: TicTacToe,
    errorGif: ErrorGif
}